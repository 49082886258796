.btn-primary {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #f57c01 !important;
  --bs-btn-border-color: #f57c01 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #f57c01 !important;
  --bs-btn-hover-border-color: #f57c01 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #f57c01 !important;
  --bs-btn-active-border-color: #f57c01 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #f57c01 !important;
  --bs-btn-disabled-border-color: #f57c01 !important;
}
.btn-primary:hover {
  --bs-btn-color: black !important;
  --bs-btn-bg: white !important;
  --bs-btn-border-color: white !important;
  --bs-btn-hover-color: black !important;
  --bs-btn-hover-bg: white !important;
  --bs-btn-hover-border-color: white !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
  --bs-btn-active-color: black !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-active-border-color: white !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: black !important;
  --bs-btn-disabled-bg: white !important;
  --bs-btn-disabled-border-color: white !important;
}
.btn-primary:active {
  --bs-btn-color: black !important;
  --bs-btn-bg: white !important;
  --bs-btn-border-color: white !important;
  --bs-btn-hover-color: black !important;
  --bs-btn-hover-bg: white !important;
  --bs-btn-hover-border-color: white !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
  --bs-btn-active-color: black !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-active-border-color: white !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: black !important;
  --bs-btn-disabled-bg: white !important;
  --bs-btn-disabled-border-color: white !important;
}
/* ============================= */
/* Comman  */
.defult-padding {
  padding: 55px !important;
}
.defult-padding-left {
  padding-left: 55px !important;
}
.defult-padding-top {
  padding-top: 55px !important;
}
.defult-padding-bottom {
  padding-bottom: 55px !important;
}
.defult-padding-right {
  padding-right: 55px !important;
}
.small-padding {
  padding: 10px !important;
}
.small-padding-top {
  padding-top: 10px !important;
}
.small-padding-bottom {
  padding-bottom: 10px !important;
}
.padding-top30 {
  padding-top: 30px !important;
}
.greyCard {
  background-color: #f7f7f7 !important;
  text-align: start;
  align-items: center;
  justify-content: space-between;
}
.blackCard {
  background-color: black !important;
  text-align: start;
  align-items: center;
  justify-content: space-between;
}
.blueCard {
  background-color: #f57c01 !important;
  text-align: start;
  align-items: center;
  justify-content: space-between;
}
.whiteCard {
  background-color: white !important;
  text-align: start;
  align-items: center;
  justify-content: space-between;
}
.whiteCard-Center {
  background-color: white !important;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.white-divider {
  width: 100px;
  height: 5px;
  background-color: white;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.divider {
  width: 100px;
  height: 5px;
  background-color: #f57c01;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 10px;
}

/* ============================= */
/* Header *(Navbar) */

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: white;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 2.5rem !important;
  --bs-gutter-y: 1rem !important;
  background-color: #ffffff00 !important ;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-top: calc(var(--bs-gutter-y) * 0.5);
  padding-bottom: calc(var(--bs-gutter-y) * 0.5);
}

.aw-nav {
  background-color: #ffffffc4 !important;
  border-radius: 10px !important;
  margin-right: 30px !important;
  margin-left: 30px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.navbar-expand-xl {
  padding: 0px !important;
}
.logo {
  width: 250px !important;
  /* user-select: none; */
  /* pointer-events: none; */
}
.nav-link {
  color: black !important;
}
.dropdown-item {
  margin-bottom: 5px;
}
.dropdown-item:hover {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: white !important;
}
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: white !important;
}

.navMenuDrop {
  --bs-navbar-nav-link-padding-x: 10px !important;
  --bs-navbar-nav-link-padding-y: 0px !important;
  background-color: white !important;
  color: black !important;
  border-radius: 5px;
  margin: 1px;
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
  padding-top: var(--bs-navbar-nav-link-padding-y);
  padding-bottom: var(--bs-navbar-nav-link-padding-y);
  text-decoration: none !important;
}

.navMenu {
  --bs-navbar-nav-link-padding-x: 10px !important;
  --bs-navbar-nav-link-padding-y: 7px !important;
  background-color: transparent !important;
  color: black !important;
  border-radius: 5px;
  margin: 1px;
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
  padding-top: var(--bs-navbar-nav-link-padding-y);
  padding-bottom: var(--bs-navbar-nav-link-padding-y);
  text-decoration: none !important;
}
.navMenu:hover {
  background-color: #fff !important;
  color: black !important;
}
.navMenu.active {
  background-color: #f57c01 !important;
  color: white !important;
}

.nav-whatsapp-btn {
  --bs-navbar-nav-link-padding-x: 10px !important;
  --bs-navbar-nav-link-padding-y: 7px !important;
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
  padding-top: var(--bs-navbar-nav-link-padding-y);
  padding-bottom: var(--bs-navbar-nav-link-padding-y);
  margin: 1px;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  /* -webkit-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
  background-color: #4ee464 !important;
  color: white !important;
  border-radius: 5px;
}

.navbar-toggler {
  color: transparent !important;
  font-size: 15px !important;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .aw-nav {
    border-radius: 10px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}

/* ============================= */
/* Home Screen */

.home-slider {
  width: 100%;
  height: 100%;
}
.home-slider-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* user-select: none; */
  /* pointer-events: none; */
}

.white-heading {
  /* user-select: none; */
  font-family: "Dosis" !important;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: white;
}

.white-paragarph {
  /* user-select: none; */
  font-family: "Open Sans" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  color: white;
  text-align: start;
}

.heading {
  /* user-select: none; */
  font-family: "Dosis" !important;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: rgb(33, 37, 41);
}
.paragarph {
  /* user-select: none; */
  font-family: "Open Sans" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  color: rgb(33, 37, 41);
  text-align: start;
}
.data-container {
  display: flex !important;
  flex-direction: row;
}

.midle-image {
  width: 70%;
  object-fit: contain;
  /* user-select: none; */
  /* pointer-events: none; */
}
.engaging-image {
  width: 90%;
  object-fit: contain;
  /* user-select: none; */
  /* pointer-events: none; */
}
.data-item {
  width: 50%;
}
.image3d {
  /* user-select: none; */
  /* pointer-events: none; */
  float: right;
  width: 50px;
  height: 50px;
  background-image: url("../public/images/favicon.png");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotateY(45deg);
  animation: rotateAnimation 3s linear infinite;
}

.ant-card-meta {
  text-align: start !important;
}

@keyframes rotateAnimation {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(1turn);
  }
}
/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .background-filter::after {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .background-filter {
    position: relative;
  }

  .background {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Use for content that should not be blurred */
  .u-non-blurred {
    position: relative;
    z-index: 1;
  }
  .home-slider-img {
    width: 100%;
    height: 335px;
    object-fit: contain;
  }
  .heading {
    /* user-select: none; */
    font-family: "Dosis" !important;
    font-weight: 700;
    font-size: 30px;
    line-height: 52px;
    color: rgb(33, 37, 41);
  }
  .white-heading {
    /* user-select: none; */
    font-family: "Dosis" !important;
    font-weight: 700;
    font-size: 30px;
    line-height: 52px;
    color: white;
  }
  .defult-padding {
    padding: 15px !important;
  }
  .logo {
    width: 235px !important;
  }
  .data-container {
    flex-direction: column;
  }
  .midle-image {
    width: 95%;
  }
  .engaging-image {
    width: 95%;
  }
  .data-item {
    width: auto;
  }
}

.client-item-20 {
  width: 20%;
  background-color: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.client-item-80 {
  width: 80%;
}

.client-heading {
  /* user-select: none; */
  font-family: "Dosis" !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 22.8571px;
  color: black;
}
.client-heading-h1 {
  /* user-select: none; */
  font-family: "Dosis" !important;
  font-weight: 500;
  font-size: 22px;
  line-height: 22.8571px;
  color: #f57c01;
  text-align: center;
}
.client-card {
  margin: 5px;
  padding: 5px;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  background-color: white;
  border-color: rgb(212, 210, 210);
}
.client-image {
  height: 50px;
  /* user-select: none; */
  /* pointer-events: none; */
  object-fit: contain;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .client-item-20 {
    width: 100%;

    border-radius: 0px;
  }
  .client-item-80 {
    width: 100%;
  }
  .client-heading {
    /* user-select: none; */
    text-align: center !important;
  }
  .client-heading-h1 {
    /* user-select: none; */
    text-align: center !important;
  }
}

/* Testimonial */
.snip1533 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #9e9e9e;
  display: inline-block;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  margin: 35px 10px 10px;
  max-width: 310px;
  min-width: 250px;
  position: relative;
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  border-top: 5px solid #f57c01;
}

.snip1533 *,
.snip1533 *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.snip1533 figcaption {
  padding: 13% 10% 12%;
}

.snip1533 figcaption:before {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: #f57c01;
  content: "\f10e";
  font-family: "FontAwesome";
  font-size: 32px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  position: absolute;
  top: -30px;
  width: 60px;
}

.snip1533 h3 {
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 10px 0 5px;
}

.snip1533 h4 {
  font-weight: 500;
  margin: 0;
  font-size: 13px;
  line-height: 24px;
  margin: 15px 0 5px;
  text-align: end;
  font-style: italic;
}

.snip1533 blockquote {
  font-style: italic;
  font-weight: 300;
  margin: 0 0 20px;
}

.owl-prev {
  min-width: 70px;
  margin: 5px;
  padding: 5px;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  color: #fff !important;
  border-color: rgb(212, 210, 210);
  background-color: #f57c01 !important;
}
.owl-next {
  min-width: 70px;
  margin: 5px;
  padding: 5px;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  color: #fff !important;
  border-color: rgb(212, 210, 210);
  background-color: #f57c01 !important;
}

/* ======================= */
/* Footer  */
/* ======================= */

.teamCard {
  background-color: #fbf1ef !important;
  text-align: start;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  align-items: center;
  justify-content: space-between;
}

.team-image {
  width: 65%;
  object-fit: contain;
  float: right;
  /* user-select: none; */
  /* pointer-events: none; */
}
/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .team-image {
    width: 95%;
  }
}
.team-heading-h1 {
  /* user-select: none; */
  font-family: "Dosis" !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 22.8571px;
  color: #f57c01;
  /* user-select: none; */
  /* pointer-events: none; */
}
.team-heading {
  /* user-select: none; */
  font-family: "Dosis" !important;
  font-weight: 900;
  font-size: 32px;
  line-height: 45.7143px;
  /* user-select: none; */
  /* pointer-events: none; */
  color: black;
}

.team-call-btn {
  display: inline-block;
  font-size: 14px;
  padding: 10px 15px;
  margin: 5px;
  font-weight: 400;
  line-height: 1.42857143;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  /* -webkit-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
  background-color: #f67c01 !important;
  color: white !important;
  border: 1px solid transparent;
  border-radius: 25px;
}
.team-whatsapp-btn {
  display: inline-block;
  font-size: 14px;
  padding: 10px 15px;
  margin: 5px;
  font-weight: 400;
  line-height: 1.42857143;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  /* -webkit-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
  background-color: #4ee464 !important;
  color: white !important;
  border: 1px solid transparent;
  border-radius: 25px;
}

ul {
  margin: 0px;
  padding: 0px;
}
.useful-links-li {
  border-bottom: 1px dashed #404040 !important;
  list-style-type: none !important;
  color: #404040;
  margin-bottom: 8px;
  padding-bottom: 8px;
  text-transform: capitalize;
}
.useful-links-li a {
  text-decoration: none !important;
  color: white;
  -webkit-transition: all 300ms ease-in !important;
  -o-transition: all 300ms ease-in !important;
  transition: all 300ms ease-in !important;
}
.footer-section {
  background-image: url("../public/images/footer/footer-background.png");
  /* background: #151414; */
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta {
  float: initial;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
  /* user-select: none; */
  /* pointer-events: none; */
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  /* user-select: none; */
  /* pointer-events: none; */
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
  
}
.footer-logo img {
  max-width: 250px;
  /* user-select: none; */
  /* pointer-events: none; */
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
  /* user-select: none; */
  /* pointer-events: none; */
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.instargam-bg {
  background: palevioletred;
}
.linkedin-bg {
  background: #0077b5;
}
.footer-widget-heading h3 {
  /* user-select: none; */
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  /* user-select: none; */
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}
.footer-widget ul li {
  display: inline-block;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #ff5e14;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background-color: #333333 !important;
  padding-bottom: 2px !important;
  padding-top: 13px !important;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  /* user-select: none; */
  /* pointer-events: none; */
  color: #878787;
}
.copyright-text p a {
  color: #ff5e14;
}
.footer-menu {
  align-items: end;
  float: right;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
  text-decoration: none;
}
/* Page Heading */
.page-head {
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 80px;
  padding-bottom: 50px;
  /* user-select: none; */
  /* pointer-events: none; */
  /* min-height: 300px; */
  background-image: url("../public/images/background/head-background.png");
  /* background-image: url("https://cnxus.org/wp-content/uploads/2021/02/Bg-contact-2048x1149.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  align-items: center;
}
.page-head h4 {
  color: white;
  /* user-select: none; */
  /* pointer-events: none; */
}
.ant-breadcrumb a {
  color: white;
  /* user-select: none; */
  /* pointer-events: none; */
  text-decoration: none;
}
.ant-breadcrumb li:last-child {
  color: white;
  text-decoration: none;
}
.last-child:hover {
  color: white;
  text-decoration: none;
}
.ant-breadcrumb .ant-breadcrumb-separator {
  margin-inline: 8px;
  color: white;
}

/* About US */

.company-heading {
  /* user-select: none; */
  font-family: "Dosis" !important;
  font-weight: 700;
  font-size: 30px;
  line-height: 52px;
  color: rgb(33, 37, 41);
}
.company-name {
  color: #f57c01;
}
.about-item {
  width: 60%;
}
.about-card {
  border-style: solid;
  margin: 5px;
  height: 265px;
  border-color: #f57c01;
  border-width: 1px;
  min-width: 200px;
  border-radius: 10px;
  visibility: visible;
  animation-duration: 1s;
  padding: 20px !important;
}

.about-card-heading {
  /* user-select: none; */
  font-family: "Dosis" !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 52px;
  color: rgb(33, 37, 41);
}
.about-card-paragraph {
  font-family: "Open Sans" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 25.2px;
  color: rgb(87, 100, 124);
  text-align: justify;
}
.about-card-image {
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotateY(45deg);
  animation: rotateAnimation 3s linear infinite;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .about-item {
    width: 100%;
  }
  .about-card {
    border-style: solid;
    margin: 5px;
    height: fit-content;
    border-color: #f57c01;
    border-width: 1px;
    min-width: auto;
    border-radius: 10px;
    visibility: visible;
    animation-duration: 1s;
    padding: 20px !important;
  }
}

/* Digital Marketing */

.digital-m-card {
  border-style: solid;
  margin: 5px;
  height: 305px;
  border-color: #f57c01;
  border-width: 1px;
  min-width: 200px;
  border-radius: 10px;
  visibility: visible;
  animation-duration: 1s;
  padding: 20px !important;
}

/* Portfolio */

.portfolio-layout {
  width: auto;
  height: auto;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  border-radius: 10px !important;
  border-color: #fff !important;
}
.card a {
  margin-bottom: var(--bs-card-title-spacer-y);
  text-decoration: none !important;
  color: rgb(33, 37, 41) !important;
}
.card-title {
  text-align: start;
}
.card-text:last-child {
  text-align: start;
  overflow: hidden;
}
.portfolio-card {
  border-style: solid;
  margin: 5px;
  border-color: rgb(208, 207, 207);
  border-width: 1px;
  width: 275px;
  border-radius: 10px;
  visibility: visible;
  animation-duration: 1s;
}
.portfolio-card:hover {
  border-style: solid;
  margin: 5px;
  border-color: #f57c01;
  border-width: 1px;
  width: 275px;
  border-radius: 10px;
  visibility: visible;
  animation-duration: 1s;
}

.nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: #f57c01 !important;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .digital-m-card {
    border-style: solid;
    margin: 5px;
    height: fit-content;
    border-color: #f57c01;
    border-width: 1px;
    min-width: auto;
    border-radius: 10px;
    visibility: visible;
    animation-duration: 1s;
    padding: 20px !important;
  }
  .portfolio-card {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .portfolio-card:hover {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
}

/* Dynamic Content */
.dynamic-content-slider {
  object-fit: contain !important;
  width: 100% !important;
  height: auto !important;
}

/* Contact Us */
.conatct-us-container {
  display: flex !important;
  flex-direction: row;
  float: right;
  background-image: url("../public/images/background/contact-background.png");
  /* background-image: url("https://www.hitachi-payments.com/wp-content/uploads/2023/04/contact-us-mobie.png"); */
  background-size: cover;
  background-repeat: no-repeat;
} /* Responsive layout - makes a one column layout instead of a two-column layout */

.contact-page-head {
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 80px;
  padding-bottom: 50px;
  background-image: url("../public/images/background/head-background.png");
  /* background-image: url("https://cnxus.org/wp-content/uploads/2021/02/Bg-contact-2048x1149.png"); */
  position: relative;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(76 121 165 / 30%) !important;
}
.conatct-heading {
  /* user-select: none; */
  font-family: "Dosis" !important;
  font-weight: 700;
  font-size: 40px;
  word-spacing: 5px;
  line-height: 52px;
  color: white;
}

.conatct-paragarph {
  /* user-select: none; */
  font-family: "Open Sans" !important;
  font-size: 17px;
  line-height: 25.2px;
  word-spacing: 5px;
  color: white;
  text-align: start;
}

.contact-form-card {
  background-color: #f1f1f1 !important;
  padding: 20px !important;
  border-radius: 10px;
}
.contact-card {
  background-color: #f1f1f1 !important;
  padding: 20px !important;
  margin: 10px !important;
  /* min-width: 310px;
  max-width: 310px;
  min-height: 75px;
  max-height: 75px; */
  text-align: center;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
}
.contact-info-heading {
  /* user-select: none; */
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: start;
  color: black !important;
}
.contact-info-icon {
  font-size: 40px;
  align-items: center;
  text-align: center;
  color: #f57c01;
  vertical-align: top;
}

.contact-item-30 {
  width: 30%;
}
.contact-item-80 {
  width: 70%;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .conatct-us-container {
    flex-direction: column;
  }
  .contact-item-30 {
    width: 100%;

    border-radius: 0px;
  }
  .contact-item-80 {
    width: 100%;
  }
  .contact-card {
    background-color: #f1f1f1 !important;
    padding: 15px !important;
    margin: 10px !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 75px;
    max-height: 75px;
    text-align: center;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
  }
}

/* Career */

.career-container {
  display: flex;
  flex-direction: row;
}

.career-title {
  font-family: "Dosis" !important;
  font-weight: 700;
  font-size: 25px;
  word-spacing: 5px;
  line-height: 52px;
  color: black;
  text-align: start;
}
.career-price {
  font-family: "Dosis" !important;
  font-weight: 700;
  font-size: 25px;
  word-spacing: 5px;
  line-height: 52px;
  color: black;
  text-align: start;
}

.career-paragarph {
  /* user-select: none; */
  font-family: "Open Sans" !important;
  font-size: 15px;
  line-height: 25.2px;
  word-spacing: 5px;
  color: grey;
  text-align: start;
}

.career-card {
  background-color: #f1f1f1 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  margin: 10px !important;
  text-align: center;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
}
.career-btn {
  background-color: #f57c01 !important;
  color: #ffff !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  text-align: center;
  border-radius: 10px;
  align-items: center;
}
.career-icon {
  font-size: 20px;
  align-items: center;
  text-align: center;
  color: #f57c01;
  vertical-align: top;
}

.career-item {
  width: 40%;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .career-item {
    width: 100%;
  }
  .career-card {
    background-color: #f1f1f1 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin: 10px !important;
    text-align: center;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
  }
  .career-title {
    font-family: "Dosis" !important;
    font-weight: 700;
    font-size: 25px;
    word-spacing: 5px;
    line-height: 52px;
    color: black;
    text-align: start;
  }
  .career-price {
    font-family: "Dosis" !important;
    font-weight: 700;
    font-size: 20px;
    word-spacing: 5px;
    line-height: 52px;
    color: black;
    text-align: start;
  }
}

/* A=========================================== */
/* Animations */

.card-animation {
  -webkit-animation: scale-up-ver-center 0.4s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-ver-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.heading-animation {
  /* user-select: none; */
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.image-animation-right-in {
  -webkit-animation: tilt-in-fwd-tr 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: tilt-in-fwd-tr 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
      skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

.image-animation-left-in {
  -webkit-animation: tilt-in-fwd-tl 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: tilt-in-fwd-tl 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/* Ant */
.ant-btn-primary {
  color: #fff;
  background-color: #f57c01;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-primary:hover {
  color: #fff;
  background-color: rgb(59 107 154) !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-primary:active {
  color: #fff;
  background-color: rgb(59 107 154) !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-col-16 {
  max-width: 100% !important;
}
/* .ant-col-8 {
  max-width: 80% !important;
}
.ant-form-vertical .ant-form-item-label > label {
  width: 200% !important;
} */

/* Modal.css */
.ImageModal__Content {
  position: absolute;
  top: 55%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff00 !important;
  border-radius: 5px;
  outline: none;
  max-height: 90vh; /* Set maximum height */
  width: 200%;
}
.ant-modal-content {
  position: relative;
  background-color: #ffffff00 !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px !important;
}

.ImageModal__Overlay {
  background: #ffffff00 !important;
}

.ImageModal__Image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  object-fit: fill;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  /* Modal.css */
  .ImageModal__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff00 !important;
    border-radius: 5px;
    outline: none;
    max-height: 90vh; /* Set maximum height */
    width: 100%;
  }
  .ImageModal__Image {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    object-fit: fill;
  }
}

/* p { */
  /* user-select: none; */
/* } */
